.footer {
  font-size: 12px;
  padding: 10px 0px;
  margin: 10px 0px;
  border-top: 1px solid black;
}
.leftCol {
  float: left;
  display: inline-block;
}
.rightCol {
  float: right;
  display: inline-block;
}
@media screen and (max-width: 875px) {
  .footer {
    padding: 10px 15px;
  }
}
/* @media screen and (max-width: 480px) {
  .rightCol {
    float: left;
  }
} */
