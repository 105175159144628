.blog_nav {
  border-top: 1px solid grey;
  padding-top: 10px;
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}
.nav_item {
  color: gray;
  padding-bottom: 5px;
  padding-right: 20px;
  font-size: 14px;
  font-weight:600;
}
.nav_item a {
  color: grey;
}
.nav_item.active a{
  /* text-decoration: underline; */
  color: black;
}
.nav_item a:hover {
    /* text-decoration: underline; */
    cursor: pointer;
    color: black;
}
@media screen and (max-width: 875px) {
  .blog_nav {
  padding: 10px 15px;
  }
}
