body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: black;
}

.tileWrapper { 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.tileWrapper { 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
/* @media screen and (max-width: 875px) {
  .header {
    padding: 10px 15px;
  }
} */

@media screen and (max-width: 600px) {
  .tileWrapper { 
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
