.thumbnail {
  text-decoration: none;
  color: black;
  flex: 1;
  margin: 5px;
  /* background: rgb(248, 248, 248); */
}
.content {
  flex: 1;
  border: 1px solid Gainsboro;
}
.content:hover {
  border: 1px solid darkgrey;
}
.imgContainer {
  width: inherit;
  z-index: 1;
}
.titleContainer {
  padding: 8px 12px;
}
.postTitle {
  /* <h2> */
  font-size: 18px;
  display: block;
  clear: both;
  font-weight: 600;
  padding: 0px;
}
.postDate {  
  /* <p> */
  font-size: 14px;
  display: block;
  clear: both;
  color: dimgrey;
  padding: 0px;
}
.thumbImage {
  width: auto;
  height: 250px;
  background-color: black;
  color: gray;
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coverImg {
  width: inherit;
  height: 250px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  border-bottom: 1px solid Gainsboro;
}