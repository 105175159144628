.header {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.header_title {
  flex: 2;
  text-align: left;
}
.blog_title {
  display: block;
  font-size: 38px;
  font-weight: bolder;
}
.blog_tagline {
  display: block;
  font-size: 14px;
  color: #333;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
}
.header_links {
  display: flex;
  flex: 1;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}
.header span {
  margin-right: 15px;
  cursor: pointer;
}

svg {
  fill: currentColor;
  transform: scale(1.1);
  transition: transform 300ms;
}
svg:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 875px) {
  .header {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    flex-direction: column;
  }
  .header_links {
    padding-top: 10px;
    align-items: center;
    justify-content: flex-start;
  }
}
