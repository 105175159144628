.postWrapper a {
  text-decoration: underline;
  color: darkblue;
}
.postWrapper .coverImg {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
  margin: 0 auto;
}

.postWrapper code {
  color: DarkGreen;
  font-weight: bold;
  font-size: 14px;
  background: whitesmoke;
  padding: 2px 4px;
}